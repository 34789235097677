.row {
  position: relative;
  cursor: pointer;
}

.selectedRow {
  background-color: #f7f7f7;
}

.menu {
  // should follow mouse position
  position: absolute;
  bottom: calc(100% + 8px);
  left: 0;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 0.5px 0.5px 1px 1px #eaeaea;
  background-color: #fff;
  padding: 8px;
  z-index: 20;
}

.deleted {
  color: #FF0000;
}

.inactive {
  color: #8C8C8C;
}

.column-size-lg {
  width: 33%;
}

.disabled_row {
  background-color: #f7f7f7;
  color: #8C8C8C;
  cursor: not-allowed;

  &:hover {

    &::after {
      display: block;
    }
  }

  &:after {
    display: none;
    content: 'This row is disabled';
    position: absolute;
    bottom: calc(100% - 8px);
    right: 24px;
    pointer-events: none;
    background-color: rgba($color: #fff, $alpha: .5);
    box-shadow: .2px .2px 4px 0px rgba($color: #000000, $alpha: .1);
    padding: 8px;
    border-radius: 5px;
  }
}