$blue: #007bff;

.mt-6 {
  margin-top: 4rem !important;
}

.mt-7 {
  margin-top: 5rem !important;
}

.list-view-actions {
  font-size: 1.35em;
}

.loader {
  position: fixed;
  top: 25%;
  left: 50%;
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: auto;
  //position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  z-index: 999;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, 0 -0.83em 0 -0.42em $blue,
      0 -0.83em 0 -0.44em $blue, 0 -0.83em 0 -0.46em $blue,
      0 -0.83em 0 -0.477em $blue;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, 0 -0.83em 0 -0.42em $blue,
      0 -0.83em 0 -0.44em $blue, 0 -0.83em 0 -0.46em $blue,
      0 -0.83em 0 -0.477em $blue;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, -0.087em -0.825em 0 -0.42em $blue,
      -0.173em -0.812em 0 -0.44em $blue, -0.256em -0.789em 0 -0.46em $blue,
      -0.297em -0.775em 0 -0.477em $blue;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, -0.338em -0.758em 0 -0.42em $blue,
      -0.555em -0.617em 0 -0.44em $blue, -0.671em -0.488em 0 -0.46em $blue,
      -0.749em -0.34em 0 -0.477em $blue;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, -0.377em -0.74em 0 -0.42em $blue,
      -0.645em -0.522em 0 -0.44em $blue, -0.775em -0.297em 0 -0.46em $blue,
      -0.82em -0.09em 0 -0.477em $blue;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, 0 -0.83em 0 -0.42em $blue,
      0 -0.83em 0 -0.44em $blue, 0 -0.83em 0 -0.46em $blue,
      0 -0.83em 0 -0.477em $blue;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, 0 -0.83em 0 -0.42em $blue,
      0 -0.83em 0 -0.44em $blue, 0 -0.83em 0 -0.46em $blue,
      0 -0.83em 0 -0.477em $blue;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, 0 -0.83em 0 -0.42em $blue,
      0 -0.83em 0 -0.44em $blue, 0 -0.83em 0 -0.46em $blue,
      0 -0.83em 0 -0.477em $blue;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, -0.087em -0.825em 0 -0.42em $blue,
      -0.173em -0.812em 0 -0.44em $blue, -0.256em -0.789em 0 -0.46em $blue,
      -0.297em -0.775em 0 -0.477em $blue;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, -0.338em -0.758em 0 -0.42em $blue,
      -0.555em -0.617em 0 -0.44em $blue, -0.671em -0.488em 0 -0.46em $blue,
      -0.749em -0.34em 0 -0.477em $blue;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, -0.377em -0.74em 0 -0.42em $blue,
      -0.645em -0.522em 0 -0.44em $blue, -0.775em -0.297em 0 -0.46em $blue,
      -0.82em -0.09em 0 -0.477em $blue;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em $blue, 0 -0.83em 0 -0.42em $blue,
      0 -0.83em 0 -0.44em $blue, 0 -0.83em 0 -0.46em $blue,
      0 -0.83em 0 -0.477em $blue;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.page-header {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.page-title-container {
  @media screen and (max-width: 768px) {
    margin-bottom: 24px;
    max-width: 100%;
    text-align: center;
  }
}

.btn {
  box-shadow: 0.2px 0.2px 10px 0 rgba($color: #000000, $alpha: 0.25);
  border-radius: 2.5px;

  &:hover {
    box-shadow: 0.2px 0.2px 10px 0 rgba($color: #000000, $alpha: 0.5);
  }
}
