
.card-item {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #f4f4f4;
    }

    &>td {
        padding: 8px 0;
    }
}